/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BraintreeInstance } from '../../../../services/Braintree';
import { MagentoData } from '../../../../services/MagentoApi';

import styles from './style.module.scss';
import formStyles from '../../../../styles/input.module.scss';

import Loader from '../../Loader';

const getNextYear = () => {
  const date = new Date();
  const newDate = date.setFullYear(date.getFullYear() + 1);

  return new Date(newDate).getFullYear();
};

const hostedFieldsOptions = {
  styles: {
    input: {
      'font-size': '16px',
    },
    'input.invalid': {
      color: '#aa001c',
    },
    'input.valid': {
      color: '#1c995f',
    },
  },
  fields: {
    number: {
      selector: '#card-number',
      placeholder: '4111 1111 1111 1111',
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123',
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: `01/${getNextYear()}`,
    },
  },
};

const BraintreeCard = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [braintreeReady, setBraintreeReady] = useState(false);

  const {
    grandTotal,
    emailAddress,
    paymentMethod,
    billingAddress,
    setLoading,
    setCheckoutSuccess,
    loggedIn,
  } = props;

  useEffect(() => {
    const cardField = document.querySelector('#braintree-hosted-field-number');
    const checkoutEl = document.querySelector('#root');

    if (
      (!BraintreeInstance.hostedFieldsInstance || !cardField) && (checkoutEl) && (!braintreeReady)
    ) {
      BraintreeInstance.createHostedFieldsInstance(
        hostedFieldsOptions,
        setBraintreeReady,
      );
    }
  }, [braintreeReady]);

  const formattedError = (error) => {
    if (typeof error === 'string') {
      return error;
    }

    if (error && error.message) {
      return error.message;
    }

    return 'Sorry, your payment was unsuccessful. Please check your card and billing details and try again.';
  };

  const payNow = async () => {
    setError(false);
    setLoading(true);

    const payment = await BraintreeInstance.hostedFieldsInstance.tokenize().then((payload) => {
      // if 3DS disabled: return this.payload;
      const threeDInnerContainer = document.createElement('div');
      threeDInnerContainer.className = 'braintree-3dsecure-popup';

      return BraintreeInstance.threeDSecureInstance.verifyCard({
        amount: parseFloat(grandTotal).toFixed(2),
        nonce: payload.nonce,
        challengeRequested: BraintreeInstance.threeDsChallengeRequested,
        billingAddress: MagentoData.formatAddressFor3DS(billingAddress),
        addFrame(err, iframe) {
          if (err) {
            return {
              error: true,
              error_message: 'Sorry, your payment was unsuccessful (Unable to verify card over 3D Secure).',
            };
          }

          // Set up your UI and add the iframe.
          threeDInnerContainer.appendChild(iframe);
          document.body.appendChild(threeDInnerContainer);
          document.body.classList.add('_with-3d-secure');
        },
        removeFrame() {
          document.body.removeChild(threeDInnerContainer);
          document.body.classList.remove('_with-3d-secure');
        },
        onLookupComplete(data, next) {
          next();
        },
      });
    }).then((response) => {
      const liability = {
        shifted: response.liabilityShifted,
        shiftPossible: response.liabilityShiftPossible,
      };

      if (liability.shifted || (!liability.shifted && !liability.shiftPossible)) {
        // happy path - liability shifted or not possible
        console.log('liability shifted or not possible');
      } else {
        return {
          error: true,
          error_message: 'Sorry, your payment was unsuccessful (3DS failed or cancelled).',
        };
      }

      const { nonce } = response;
      const email = emailAddress;

      const paymentResponse = MagentoData.submitPayment(
        {
          payment_method_nonce: nonce,
          is_active_payment_token_enabler: saveCard,
          device_data: BraintreeInstance.deviceData,
        },
        email,
        billingAddress,
        paymentMethod,
      );

      return paymentResponse;
    }).catch((err) => {
      let errorMessage = '';
      if (err.code === 'THREEDS_LOOKUP_VALIDATION_ERROR') {
        errorMessage = err.details.originalError.details.originalError.error.message;
        if (errorMessage) {
          errorMessage = `Sorry, your payment was unsuccessful. (${errorMessage}).`;
          setError(errorMessage);
        }
      }
      setLoading(false);
      return {
        error: true,
        error_message: errorMessage,
      };
    });

    if (payment && payment.status === 200) {
      setSuccess(true);
      setCheckoutSuccess();
    } else {
      setError(true);
      if (payment && payment.error) {
        if (payment.error_message) {
          setError(payment.error_message);
        } else if (payment.message) {
          setError(payment.message);
        }
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.braintreeCard}>
      <>
        <div className={formStyles.field}>
          <label htmlFor="card-number" className={formStyles.label}>Card Number*</label>
          <div className={formStyles.control}>
            <div id="card-number" className={`${styles.braintreeCard__input}`} />
          </div>
        </div>

        <div className={`${formStyles.fieldGroup} ${styles.cardFields}`}>
          <div className={`${formStyles.field} ${formStyles.fieldSmall}`}>
            <label htmlFor="expiration-date" className={formStyles.label}>Expiration Date*</label>
            <div className={formStyles.control}>
              <div id="expiration-date" className={`${styles.braintreeCard__input}`} />
            </div>
          </div>

          <div className={`${formStyles.field} ${formStyles.fieldSmall}`}>
            <label htmlFor="cvv" className={formStyles.label}>Card Verification Number*</label>
            <div className={formStyles.control}>
              <div id="cvv" className={`${styles.braintreeCard__input}`} />
            </div>
            <p className={formStyles.hint}>Three digits located on the rear of the card</p>
          </div>
        </div>

        {loggedIn && (
          <label>
            <input type="checkbox" value={saveCard} onChange={() => { setSaveCard(!saveCard); }} />
            Save this card for next time
          </label>
        )}

        {(error) && (<div className="Alert AlertError">{formattedError(error)}</div>)}
        <div className={formStyles.formActions}>
          {(!success) && <button type="submit" onClick={() => payNow()}>Pay now</button>}
          {(success) && (<p>Success! Please wait...</p>)}
          {(success && isDev) && (<p>Dev mode: Please manually clear your quote from browser data.</p>)}
        </div>
      </>
      {!braintreeReady && (<Loader loading position="absolute" />)}
    </div>
  );
};

BraintreeCard.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  grandTotal: PropTypes.number,
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  loggedIn: PropTypes.bool,
};

BraintreeCard.defaultProps = {
  paymentMethod: {
    code: '',
  },
  grandTotal: 0,
  billingAddress: {},
  emailAddress: '',
  setLoading: () => {},
  setCheckoutSuccess: () => {},
  loggedIn: false,
};

export default BraintreeCard;
